import React from 'react';
import Layout from "../../../../libs/components/Layout";
import SectionNotFound from "../../../../libs/components/Sections/SectionNotFound";

const NotFoundPage = () => (
    <Layout isDisabledMetaIndex={true}>
        <SectionNotFound />
    </Layout>
);

export default NotFoundPage;
